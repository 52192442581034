import React from "react";
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton";

import { useApp } from "entities/app";
import { MIcons } from "shared/ui/icon/checkbox/mui_icon";
import { useWrapSnackbar } from "shared/hooks/wrap-snackbar";
import { getObjectFromParams } from "shared/utils/url";
import ConditionModal from "shared/ui/condition-modal";
import { $api } from "shared/http/admin";

import './style.css';

const DEFAULT_ERROR_MESSAGE = "Что-то пошло не так!"
const DEFAULT_SUCCESS_MESSAGE = "Действие выполнено успешно"

export const ActionButtonComponent: React.FC<{
  children?: React.ReactNode | string,
  componentName: string,
  type?: string,
  size?: string,
  actionUrl?: string,
  modalText?: string,
  subscribeOn?: string,
  subscriber?: string,
  leftIcon?: any
  rightIcon?: any
  options?: any,

  height?: number | string,
  width?: number | string,
  margin?: number[],
  padding?: number[],
}> = ({ 
  componentName, 
  children, 
  actionUrl, 
  subscriber,
  modalText,
  options = {},
  type='text', 
  size='medium', 
  subscribeOn='',
  leftIcon = <></>,
  rightIcon = <></> ,
  height = 'unset', 
  width = '100%',
  margin = [0, 0, 0, 0],
  padding = [16, 16, 16, 16]
}) => {

  const { enqueueWrapSnackbar } = useWrapSnackbar();
  const app = useApp();

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const getHref = (url: string, data: any) => {
    if (!data) return url;

    const matchReplace = /\{\{(\w+)\}\}/;
    const templateField = url.match(matchReplace)?.at(0) || "";
    const nameField = url.match(matchReplace)?.at(-1) || "";
    
    if (templateField && nameField) {
      let href = url.replace(templateField, data[nameField]);
      return href;
    } else {
      return url;
    }
  }

  const sendRequest = async () => {
    try {
      const fullUrl = getHref(actionUrl ?? "", options.data) ?? '#';
      const body = { 
        body: {
          query: {...getObjectFromParams(window.location.search)}
        } 
      }
      
      const response = await $api.post(fullUrl, body);
      let message = response.data.message;
      if (message === "Success") {
        message = DEFAULT_SUCCESS_MESSAGE;
      }
      enqueueWrapSnackbar(message, 'success');
      setTimeout(() => app.addSignals(`subscriber_${subscriber}`, 'refresh'), 1500);
      handleCloseModal();
    } catch (e: any) {
      enqueueWrapSnackbar(e.response?.data?.message || DEFAULT_ERROR_MESSAGE, 'error');
    }
  }

  const handleActionButtonClick = async (e: any) => {
    e.stopPropagation();
    if (modalText) {
      handleOpenModal();
    } else {
      sendRequest();
    }
  }

  return (
    <>
      {children
        // @ts-ignore
        ? <Button
          componentName={componentName}
          className="action-button"
          fullWidth
          size={size}
          variant={type}
          startIcon={leftIcon && leftIcon.type.render()}
          endIcon={rightIcon && rightIcon.type.render()}
          sx={{ 
            fontSize: 12, 
            whiteSpace: 'nowrap',
            lineHeight: 0,
            width,
            height,
            margin: margin.join('px ') + 'px',
            padding: padding.join('px ') + 'px',
          }}
          onClick={handleActionButtonClick}
        >
          {children}
        </Button>
        : <IconButton 
            size="small" 
            className="action-button"
            onClick={handleActionButtonClick}
          >
          {leftIcon ? leftIcon.type.render() : null}
          {rightIcon ? rightIcon.type.render() : null}
        </IconButton>
      }
      {
        modalText && openModal
        ? <ConditionModal
            title={modalText}
            open={openModal} 
            onClose={handleCloseModal} 
            onYes={sendRequest} 
          />
        : null
      }
    </>
  )
}

export const buildActionButtonComponent = (component: any, options?: any) => (
  <ActionButtonComponent 
    componentName={component.component}
    size={component['size']}
    type={component['type']}
    subscribeOn={component['subscribe_on']}
    subscriber={component['subscriber']}
    actionUrl={component['action_url']}
    modalText={component['modal_text']}
    // @ts-ignore
    leftIcon={component['left_icon'] ? MIcons[component['left_icon']] : null}
    // @ts-ignore
    rightIcon={component['right_icon'] ? MIcons[component['right_icon']] : null}
    height={component['height']} 
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
    options={options}
  >
    {component['label']}
  </ActionButtonComponent>
)