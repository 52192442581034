import React from "react";
import { Box, Checkbox, FormControlLabel, SxProps, TextField } from "@mui/material"
import { useFilter } from "entities/filter";
import { useFormFields } from "entities/form-field";
import { encode } from "base64-arraybuffer";
import { useWrapSnackbar } from "shared/hooks/wrap-snackbar";

const labelSxProps: SxProps = {
  m: 0,
  backgroundColor: '#5B90E1',
  color: '#ffffff',
  padding: '5px 10px',
  borderRadius: '4px',
  transition: '250ms',
  '&:hover': {
    opacity: 0.6
  }
}

const INVALID_TYPE_FILE = "Неправильный формат файла! Поддерживаемые форматы: JPEG, JPG, PNG";

export const FileComponent: React.FC<{
  componentName: string,
  name?: string,
  label?: string,
  formId?: string
}> = ({ componentName, name, label, formId }) => {
  const { enqueueWrapSnackbar } = useWrapSnackbar();
  // const filter = useFilter();
  const formFields = useFormFields();
  const acceptedFile = ["jpeg", "jpg", "png", "heic"];

  // @ts-ignore
  const [value, setValue] = React.useState<File | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      try {
        const ext = file.name.split('.').at(-1);
        if (ext) {
          if (!acceptedFile.includes(ext.toLowerCase())) {
            enqueueWrapSnackbar(INVALID_TYPE_FILE, "error");
            return;
          }
        } else {
          enqueueWrapSnackbar(INVALID_TYPE_FILE, "error");
          return;
        }
      } catch {
        enqueueWrapSnackbar(INVALID_TYPE_FILE, "error");
      }


      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        if (e.target && e.target.result) {
          const bytes = e.target.result as ArrayBuffer;

          formFields.updateFormField({
            form_id: formId ?? "",
            name: name ?? "",
            value: encode(bytes)
          });
          
        }
      }

      setValue(file);
      fileReader.readAsArrayBuffer(file);
      
    }
  }

  return (
    <Box sx={{ p: 1 }}>
      <FormControlLabel 
        label={value ? `${label}: ${value.name}` : label}
        sx={labelSxProps}
        control={
          <TextField
            // @ts-ignore
            componentName={componentName}
            type="file"
            // value={value}
            onChange={handleChange}
            data-form-id={formId}
            data-name={name}
            // data-value={value}
            data-label={label}
            data-component={componentName}
            sx={{ display: "none" }}
            inputProps={{
              accept: "image/jpeg, image/jpg, image/png, image/heic"
            }}
          />
      } /> 
    </Box>
  )
}

export const buildFileComponent = (component: any) => (
  <FileComponent 
    componentName={component.component}
    name={component['name']}
    formId={component['form_id']}
    label={component['label']}
  />
)