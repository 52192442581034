
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Column, ColumnType } from 'entities/table-component/model/state';

import { buildLinkComponent } from 'shared/component/link';
import { buildActionButtonComponent } from 'shared/component/action-button';
import { buildTextComponent } from 'shared/component/text';
import { buildBadgeComponent } from 'shared/component/badge';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { buildPopoverComponent } from 'shared/component/popover';
import { buildCollapseComponent } from 'shared/component/collapse';
import { buildModalComponent } from 'shared/component/modal';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f6fd',
    color: theme.palette.text.primary,
    // textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '0 16px'
  },
}));

export const buildDefaultCell = (cellValue: any) => {
  if (cellValue === null) return '-';
    if (typeof(cellValue) == 'string') {
      return cellValue ?? '-';
    } else if (typeof(cellValue) == 'object'){
      // @ts-ignore
      const {value, text_color, bg_color} = cellValue;
      return <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'  
      >
        <Typography 
          width='min-content'
          fontSize={12} 
          fontWeight={500}
          letterSpacing={1}
          textTransform='uppercase'
          color={text_color ?? 'black'}
          whiteSpace='nowrap'
          sx={{ 
            px: 1,
            py: 0.5,
            borderRadius: '6px',
            backgroundColor: bg_color ?? 'transparent',
          }}
        >
          {value}
        </Typography>
      </Stack>
    } else {
      return '-';
    }
}

export const getCellByColumnType = (columnType: ColumnType, cellValue: any, column: Column, row?: any) => {
  switch (columnType.component) {
    case "link": 
      return buildLinkComponent(columnType, cellValue, column.align, row);
    case "action_button":
      return buildActionButtonComponent(columnType, { data: row });
    case "text":
      return buildTextComponent(
        columnType, 
        cellValue === null || cellValue === undefined ? '-' : cellValue, 
        cellValue !== null  || cellValue !== undefined ? column.align : 'center'
      );
    case "badge":
      return buildBadgeComponent(cellValue, columnType.mapping);
    case "collapse":
      return buildCollapseComponent({
        label: cellValue,
        content: columnType.content
      });
    case "popover":
      return buildPopoverComponent(columnType);
    case "modal":
      return buildModalComponent(columnType, { data: row });
    default:
      return buildDefaultCell(cellValue);
  }
}