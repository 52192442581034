import { useApp } from "entities/app";
import { useFilter } from "entities/filter";
import { IFilter } from "entities/filter/model/state";
import { useTable } from "entities/table";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import './style.css';
import { buildComponents } from "shared/utils/build";
import { normalizeValue } from "shared/utils/validation";
import { $api } from "shared/http/admin";
import { fromFormFieldsArrayToObject } from "shared/utils/params";
import { useFormFields } from "entities/form-field";
import { useWrapSnackbar } from "shared/hooks/wrap-snackbar";
import handleOnSuccessAction from "./lib/actions-handle";
import { IFormField } from "entities/form-field/model/state";

const DEFAULT_ERROR_MESSAGE = "Что-то пошло не так!";
const INVALID_DATA = "Данные заполнены неправильно!";
const DEFAULT_SUCCESS_MESSAGE = "Действие выполнено успешно";


export const SpecialFormComponent: React.FC<{
  children?: React.ReactNode,
  componentName: string,
  id: string,
  subscriber: string,
  enctype?: string,
  method?: string,
  action?: string,
  loadFrom?: string,
  onSuccess?: any[],
  height?: number | string,
  width?: number | string,
  margin?: number[],
  padding?: number[],
  options?: any,
}> = ({ 
    children, 
    id, 
    method, 
    enctype,
    componentName,
    loadFrom,
    subscriber,
    options = {},
    onSuccess = [],
    action = '/',
    height = 'unset',
    width = '800px',
    margin = [0, 0, 0, 0],
    padding = [24, 16, 24, 16]
  }) => {

  const app = useApp();
  const { enqueueWrapSnackbar } = useWrapSnackbar();
  const formFields = useFormFields();
  const [isLoaded, setIsLoaded] = React.useState(loadFrom ? false : true);

  const getHref = (url: string, data?: any) => {
    if (!data) return url;

    const matchReplace = /\{\{(\w+)\}\}/;
    const templateField = url.match(matchReplace)?.at(0) || "";
    const nameField = url.match(matchReplace)?.at(-1) || "";
    
    if (templateField && nameField) {
      let href = url.replace(templateField, data[nameField]);
      return href;
    } else {
      return url;
    }
  }

  const getParentNodeComponentName = () : string | null => {
    const parentNode = document.getElementById(id!)?.parentNode;
    let componentName = null;
    if (parentNode) {
      // @ts-ignore
      componentName = parentNode.attributes.componentname.nodeValue;
    }

    return componentName;
  }

  const closeParentNode = () => {
    const parentNodeComponentName = getParentNodeComponentName();
    
    if (parentNodeComponentName) {
      app.addSignals(parentNodeComponentName, 'close')
    }
  }

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const href = getHref(action, options?.data);

    const fields: IFormField[] = formFields.getFormFields(id).map(ff => ff);

    const isValid = fields.map(f => f.isValid === undefined ? true : f.isValid).reduce((prev, curr) => prev && curr);
    if (!isValid) {
      enqueueWrapSnackbar(INVALID_DATA, 'warning');
      return;
    }
    // const formComponents = document.querySelectorAll(`[data-form-id="${id}"]`);
    // formFields.getFormFields(id).forEach(comp => {
    //   fields.push({...comp});
    // });

    try {
      let body = fromFormFieldsArrayToObject(fields.map(f => ({ name: f.name, value: f.value })));
      if (options?.extraBody) {
        body = {...body, ...options.extraBody}
      }
      const response = await $api.post(href, body);
      let message: string = response.data.message;
      if (message === "Success") {
        message = DEFAULT_SUCCESS_MESSAGE;
      }
      enqueueWrapSnackbar(message, 'success');

      setTimeout(() => app.addSignals(`subscriber_${subscriber}`, 'refresh'), 1500);
      setTimeout(() => app.addSignals(`subscriber_main_page`, 'refresh'), 1500);
      // setTimeout(() => app.addSignals(`subscriber_detail_page`, 'refresh'), 1500);
      
      if (onSuccess.length !== 0) handleOnSuccessAction(onSuccess, response)

      closeParentNode();
      
    } catch (e: any) {
      enqueueWrapSnackbar(e.response?.data?.message || DEFAULT_ERROR_MESSAGE, 'error');
    }
  }

  const resetForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoaded(false);
    formFields.resetFormFields(id);
    setTimeout(() => setIsLoaded(true), 500);
  }

  const loadData = async (loadFrom: string) => {
    try {
      const href = getHref(loadFrom, options.data);
      const response = await $api.get(href);

      formFields.setFormFields([...response.data]);
      
    } catch (e: any) {
      console.log(e);
    }
    setIsLoaded(true);
  }

  React.useEffect(() => {
    if (loadFrom) {
      loadData(loadFrom);
    }
  }, [loadFrom])

  React.useEffect(() => {
    return () => {
      formFields.resetFormFields(id);
    }
  }, [])

  return (
    <form 
      // @ts-ignore
      componentName={componentName} 
      id={id} 
      encType={enctype} 
      method={method}
      onSubmit={submitForm}
      onReset={resetForm}
      className="special-form"
      style={{
        margin: margin.join('px ') + 'px',
        padding: padding.join('px ') + 'px',
        height: height,
        width: width,
      }}
    >
      {isLoaded ? children : null}
    </form>
  )
}

export const buildSpecialFormComponent = (component: any, options?: any) => (
  <SpecialFormComponent 
    componentName={component.component}
    id={component['id']}
    enctype={component['enctype']}
    method={component['method']}
    action={component['action']}
    loadFrom={component['load_from']}
    onSuccess={component['on_success']}
    height={component['height']}
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
    subscriber={component['subscriber']}
    options={options}
  >
    {component['content'] != undefined ? component['content'].map((c: any) => buildComponents(c, options)) : null}
  </SpecialFormComponent>
)