import { buildActionButtonComponent } from "shared/component/action-button";
import { buildBackButtonComponent } from "shared/component/back-button";
import { buildBadgeComponent } from "shared/component/badge";
import { buildButtonComponent } from "shared/component/button";
import { buildCardComponent } from 'shared/component/card';
import { buildCarouselComponent } from 'shared/component/carousel';
import { buildDatePickerComponent } from 'shared/component/date-picker';
import { buildDatetimePickerComponent } from 'shared/component/datetime-picker';
import { buildDatetimeRangeComponent } from "shared/component/datetime-range";
import { buildFormComponent } from "shared/component/form";
import { buildGridComponent } from "shared/component/grid";
import { buildHeadingComponent } from "shared/component/heading";
import { buildHorizontalLineComponent } from "shared/component/horizontal-line";
import { buildInlineKeyValueComponent } from 'shared/component/inline-key-value';
import { buildInputComponent } from "shared/component/input";
import { buildLinkComponent, buildOuterLinkComponent } from "shared/component/link";
import { buildPageComponent } from "shared/component/page";
import { buildPopoverComponent } from "shared/component/popover";
import { buildSelectComponent } from 'shared/component/select';
import { buildTextComponent } from "shared/component/text";
import { buildTableComponent } from 'shared/ui/table/table';
import { buildCheckboxComponent } from 'shared/component/checkbox';
import { buildModalComponent } from 'shared/component/modal';
import { buildSpecialFormComponent } from "shared/component/special-form";
import { buildListComponent } from "shared/component/list";
import { buildCollapseComponent } from "shared/component/collapse";
import { buildMapComponent } from "shared/component/map";
import { buildTabsContainerComponent } from "shared/component/tabs-container";
import { buildFileComponent } from "shared/component/file";
import { buildRadioGroupComponent } from "shared/component/radio-group";
import { buildTreeViewComponent } from "shared/component/tree-view";
import { buildTreeSelectComponent } from "shared/component/tree-select";

export const buildComponents = (component: any, options?: any) => {
  if (!component) return;
  
  let ReactComponent: React.ReactNode = <></>;

  switch (component.component) {
    case "page": 
      ReactComponent = buildPageComponent(component);
      break;
    case "grid": 
      ReactComponent = buildGridComponent(component);
      break;
    case "list": 
      ReactComponent = buildListComponent(component);
      break;
    case "text": 
      ReactComponent = buildTextComponent(component);
      break;
    case "action_button":
      ReactComponent = buildActionButtonComponent(component, options);
      break;
    case "button":
      ReactComponent = buildButtonComponent(component);
      break;
    case "back_button":
      ReactComponent = buildBackButtonComponent(component);
      break;
    case "heading": 
      ReactComponent = buildHeadingComponent(component);
      break;
    case "horizontal_rule": 
      ReactComponent = buildHorizontalLineComponent(component);
      break;
    case "popover":
      ReactComponent = buildPopoverComponent(component, options);
      break;
    case "modal":
      ReactComponent = buildModalComponent(component, options);
      break;
    case "form":
      ReactComponent = buildFormComponent(component);
      break;
    case "special-form":
      ReactComponent = buildSpecialFormComponent(component, options);
      break;
    case "input": 
      ReactComponent = buildInputComponent(component);
      break;
    case "table": 
      ReactComponent = buildTableComponent(component);
      break;
    case "select": 
      ReactComponent = buildSelectComponent(component);
      break;
    case "datetime_range": 
      ReactComponent = buildDatetimeRangeComponent(component);
      break;
    case "datetime_picker": 
      ReactComponent = buildDatetimePickerComponent(component);
      break;
    case "date_picker": 
      ReactComponent = buildDatePickerComponent(component);
      break;
    case "carousel": 
      ReactComponent = buildCarouselComponent(component);
      break;
    case "inline-key-value": 
      ReactComponent = buildInlineKeyValueComponent(component);
      break;
    case "card": 
      ReactComponent = buildCardComponent(component);
      break;
    case "link":
      ReactComponent = buildLinkComponent(component);
      break;
    case "outer-link":
      ReactComponent = buildOuterLinkComponent(component);
      break;
    case "badge":
      ReactComponent = buildBadgeComponent(component);
      break;
    case "checkbox":
      ReactComponent = buildCheckboxComponent(component);
      break;
    case "collapse":
      ReactComponent = buildCollapseComponent(component);
      break;
    case "map":
      ReactComponent = buildMapComponent(component);
      break;
    case "tabs-container":
      ReactComponent = buildTabsContainerComponent(component);
      break;
    case "file":
      ReactComponent = buildFileComponent(component);
      break;
    case "radio-group":
      ReactComponent = buildRadioGroupComponent(component);
      break;
    case "tree-view":
      ReactComponent = buildTreeViewComponent(component);
      break;
    case "tree-select":
      ReactComponent = buildTreeSelectComponent(component);
      break;
  }

  return ReactComponent
}
