import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

import CircularProgress from '@mui/material/CircularProgress';
import { TableHeader } from './table-header';
import { TableBodyContent } from './table-body';
import { useApp } from 'entities/app';
import { useTable } from 'entities/table';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useFilter } from 'entities/filter';
import { Box } from '@mui/material';
import TableSettings from 'features/filter/ui/table-settings';
import DataTablePagination from '../pagination/pagination';
import { buildActionButtonComponent } from 'shared/component/action-button';
import TableFilterChips from './table-filter-chips';
import { useBindNavigator } from 'shared/hooks/bind-navigator';
import { useLocationParams } from 'shared/hooks/params';
import { removeParam } from 'shared/utils/params';
import { ISignal } from 'entities/app/model/state';
import { useFormFields } from 'entities/form-field';

const EXCLUDE_PARAMS = [
  'rows',
  'page',
  'tab'
]


interface TableComponentProps {
  id: string;
  componentName?: string;
  dataUrl?: string;
  dataMethod?: string;
  rowsPerPage?: number;
  subscribeOn: string;
  columns?: any[];
  rows?: any[];
  paginationEnabled?: boolean;
  enableRowsCount?: boolean;
  customize?: boolean;
  footer?: any[];
  height?: number | string;
  width?: number | string;
  margin?: number[];
  padding?: number[];
}

export const TableComponent: React.FC<TableComponentProps> = ({
  id,
  dataMethod,
  subscribeOn,
  rows,
  dataUrl,
  rowsPerPage = 10,
  footer = [],
  columns = [],
  height = '100%',
  width = '100%',
  margin = [0, 0, 0, 0],
  padding = [0, 0, 0, 0],
  paginationEnabled = true,
  enableRowsCount = true,
  customize = true,
}) => {
  const navigation = useNavigate();
  const app = useApp();
  const { addToNavigateBar } = useBindNavigator<any>();
  const { params } = useLocationParams<any>(window.location.search);

  const table = useTable(subscribeOn);
  const isDynamicTable = dataUrl !== undefined

  const tableRef = React.useRef(null);
  const filter = useFilter({ formId: subscribeOn ?? '', initFilters: window.location.search });
  const formField = useFormFields();

  const [commonTableRows, setCommonTableRows] = React.useState<any[]>([]);
  const [commonTableHeader, setCommonTableHeader] = React.useState<any[]>([]);

  const [page, setPage] = React.useState(params.page ? parseInt(params.page) : 1);
  const [rowsPerPageTable, setRowsPerPage] = React.useState(params.rows ? parseInt(params.rows) : rowsPerPage);


  const handleChangePage = async (event: any, newPage: number) => {
    if (isDynamicTable && subscribeOn) {
      
      setPage(newPage + 1);

      if (!table.countRows) {
        table.setIsNextPageTableDataLoading(true);
      }

      // @ts-ignore
      tableRef.current.scroll({
        top: 0
      })

      await table.getData(
        dataUrl, {
          limit: rowsPerPageTable,
          offset: newPage * rowsPerPageTable,
          query: removeParam(params, EXCLUDE_PARAMS)
        } 
      );

      addToNavigateBar({
        page: newPage + 1,
      })
      

      if (!table.countRows) {
        table.setIsNextPageTableDataLoading(false);
      }
    }
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value);
    if (subscribeOn && isDynamicTable) {
      // app.setAppState({rowsPerPage: newRowsPerPage});
      setRowsPerPage(newRowsPerPage);
      setPage(1);

      await table.getData(
        dataUrl, {
          limit: newRowsPerPage,
          offset: 0,
          query: removeParam(params, EXCLUDE_PARAMS)
        } 
      );

      addToNavigateBar({
        rows: newRowsPerPage,
        page: 1
      })
    }
  };

  const handleDeleteFilterChip = (name: string) => {
    if (isDynamicTable) {
      const excludeNames = [name];
      let updatedFilters = filter.getFormFilters(subscribeOn);

      const addictedField = updatedFilters.find(uf => uf.addictedby && uf.addictedby === name);
      if (addictedField) {
        excludeNames.push(addictedField.name);
      }
      
      filter.removeFilter(subscribeOn, excludeNames);
      formField.removeFormFields(subscribeOn, excludeNames);
      updatedFilters = filter.getFormFilters(subscribeOn, { excludeNames });

      addToNavigateBar({
        ...params,
        ...excludeNames.reduce((prev, curr) => ({...prev, [curr]: null}), {})
      })
      
      table.getData(
        dataUrl, {
          limit: rowsPerPageTable,
          offset: 0,
          query: filter.toParamsFromArray(updatedFilters)
        }
      );
    }
  }

  const handleSignal = (signal: ISignal) => {
    const { action, args } = signal;

    switch(action) {
      case 'refresh':
        table.getData(
          dataUrl!, {
            limit: rowsPerPageTable,
            offset: (page - 1) * rowsPerPageTable,
            query: args?.params || filter.toParamsFromArray(filter.getFormFilters(subscribeOn)) || {}
          } 
        );
        break
    }
  }

  React.useEffect(() => {
    const signal = app.signals[`subscriber_${id}`];
    if (signal) {
      handleSignal(signal);
      app.clearSignal(`subscriber_${id}`);
    }
  }, [app.signals[`subscriber_${id}`]])


  React.useEffect(() => {
    if (subscribeOn) {
      table.initTable(subscribeOn);
    }
  }, [subscribeOn])

  React.useEffect(() => {
    if (isDynamicTable) {
      if (table.isInit) {
        const savedColumnOrder = localStorage.getItem(`${subscribeOn}_columnOrder`);
        // table.setServiceAndTable(serviceSource ?? "", tableSource ?? "");
        
        table.getData(
          dataUrl, {
            limit: parseInt(params.rows) || rowsPerPageTable,
            offset: (parseInt(params.page) - 1 || page - 1) * (parseInt(params.rows) || rowsPerPageTable),
            query: removeParam({
              ...params,
              ...filter.toParamsByFormId(subscribeOn || "")
            }, EXCLUDE_PARAMS)
          } 
        );
        
        // table.setIsLoadingTableGlobal(subscribeOn, true);
        table.setDataUrl(subscribeOn, dataUrl);
        app.setAppState({rowsPerPage: rowsPerPageTable});
        table.setHeader(subscribeOn, savedColumnOrder ? [...JSON.parse(savedColumnOrder)] : columns);
        table.setHiddenHeader(subscribeOn, columns.map(c => ({...c, hide: false})));
        table.setRowsPerPage(subscribeOn, rowsPerPage);
        
        addToNavigateBar({
          ...params,
          ...filter.toParamsByFormId(subscribeOn || ""),
          rows: params.rows || rowsPerPage,
          page: params.page || page,
        })
      }
    } 
  }, [table.isInit])

  React.useEffect(() => {
    if (rows) {
      setCommonTableRows(rows ?? []);
      setCommonTableHeader(columns);
    }
  }, [rows])

  if (isDynamicTable && !table.isInit) {
    return <></>
  }

  return (
    <>
      {isDynamicTable && 
        <>
          {customize ? <TableSettings table={table} /> : null} 
          <TableFilterChips
            chips={filter.getFormFilters(subscribeOn)}
            onDelete={(name) => handleDeleteFilterChip(name)}
          />
        </>
      }

      <Box sx={{ 
          width: width, 
          // minHeight: 300, 
          overflow: 'hidden', 
          flexGrow: 1, 
          position: 'relative',
          margin: margin.join('px ') + 'px',
          padding: padding.join('px ') + 'px',
        }}
      >
        {table.isLoadingTableGlobal && <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', zIndex: 1000 }} />}
        <TableContainer
          ref={tableRef} 
          sx={{ 
            height: height,
            position: 'relative',
            opacity: table.isLoadingTableGlobal ? '0.0' : '1'
          }}
        >
          
          {table.isAllColumnsHide()
            ? <Stack justifyContent='center' alignItems='center' sx={{ width: '100%', height: '100%'}}>
                <Typography
                  fontSize={18}
                  color='#888'
                  textAlign='center'
                  textTransform='uppercase'
                  width={450}
                >
                  Колонки скрыты
                </Typography> 
              </Stack> 
            : table?.requestStatus?.success || rows
              ? <Table stickyHeader size='small'>
                  <TableHeader columns={isDynamicTable ? table.getColumns() : commonTableHeader} defaultHeaderBackground={isDynamicTable} />
                  <TableBodyContent 
                    rows={isDynamicTable ? table.rows : commonTableRows} 
                    header={isDynamicTable ? table.getColumns() : commonTableHeader} 
                    page={page} 
                    rowsPerPage={rowsPerPageTable}
                    isCommonTable={!isDynamicTable}
                  />
                </Table>
              : null
          }
        </TableContainer>
      </Box>
      <Stack direction='row' sx={{ width: '100%' }}>
        {isDynamicTable
          ? footer.length != 0 && <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2} sx={{py: 2, px: 4, width: '100%'}}>
              {footer.map(f =>
                <Stack direction='column' justifyContent='flex-start' alignItems='flex-start'>
                  <Typography fontSize={12} color='rgba(0, 0, 0, 0.60)' fontWeight={400}>{f['title']}</Typography>
                  {/* @ts-ignore */}
                  <Typography fontSize={14} color='#000000' fontWeight={400}>{(table?.footerData || {})[f['name']]}</Typography>
                </Stack>
              )}
            </Stack>
          : footer.length != 0 && <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} sx={{py: 2, px: 2, width: '100%'}}>
              {footer.map(f =>
                <Stack direction='row' justifyContent='center' alignItems='center'>
                  <Typography 
                    fontSize={16} 
                    color='rgba(0, 0, 0, 0.60)' 
                    fontWeight={600}
                    sx={{
                      mr: 1
                    }}
                  >
                    {f['title']}
                  </Typography>
                  {/* @ts-ignore */}
                  {
                    typeof(f['value']) == 'string'
                      ? <Typography fontSize={14} color='#000000' fontWeight={400}>{f['value']}</Typography>
                      : buildActionButtonComponent(f['value'])
                  }
                  
                </Stack>
              )}
            </Stack>
        }
        {isDynamicTable && <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center' sx={{py: 2, px: 4, width: '100%'}}>
          <DataTablePagination 
            isLoading={table.isNextPageTableDataLoading}
            page={page - 1} 
            rowsPerPage={rowsPerPageTable}
            count={table.countRows} 
            handleChangePage={handleChangePage} 
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTableEmpty={table.isNextPageTableDataEmpty}
          />
        </Stack>}
      </Stack>
    </>
  );
}



export const buildTableComponent = (component: any) => (
  <TableComponent 
    componentName={component.component}
    columns={component['columns']}
    rows={component['rows']}
    dataMethod={component['data_method']}
    dataUrl={component['data_url']}
    enableRowsCount={component['enable_rows_count']}
    id={component['id']}
    paginationEnabled={component['pagination_enabled']}
    rowsPerPage={component['rows_per_page']}
    subscribeOn={component['subscribe_on']}
    footer={component['footer']}
    height={component['height']}
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
    customize={component['customize']}
  />
)